import React, { useState } from 'react';
import { ArrowLeft, Send, CheckCircle, XCircle, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { CampaignData } from './types/campaign';
import { campaignService } from './api';


interface Message {
  type: 'bot' | 'user' | 'summary' | 'date-picker';
  content: string | React.ReactNode;
  isSummary?: boolean;
  showActions?: boolean;
}

const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const DatePickerMessage = ({ onSelect }: { onSelect: (date: string) => void }) => {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-3">
        <Calendar className="text-gray-500" size={20} />
        <input
          type="date"
          min={today.toISOString().split('T')[0]}
          value={selectedDate}
          onChange={handleDateChange}
          className="border border-gray-200 rounded-lg px-3 py-2 focus:outline-none focus:border-[#09122C]"
        />
      </div>
      {selectedDate && (
        <button
          onClick={() => onSelect(selectedDate)}
          className="bg-[#09122C] text-white px-4 py-2 rounded-lg hover:bg-opacity-90"
        >
          Confirm Date
        </button>
      )}
    </div>
  );
};

const ChatWindow = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [campaignData, setCampaignData] = useState<CampaignData>({
    name: '',
    targetAudience: '',
    goal: '',
    startDate: '',
    status: 'draft'
  });

  const [messages, setMessages] = useState<Message[]>([
    {
      type: 'bot',
      content: "Hi! I'll help you create your campaign. First, what would you like to name your campaign?"
    }
  ]);

  const questions = [
    "Hi! I'll help you create your campaign. First, what would you like to name your campaign?",
    "Great! Now, what's your target audience for this campaign?",
    "What's the main goal of your campaign?",
    "When would you like to start this campaign?"
  ];

  const handleDateSelection = (date: string) => {
    const updatedData = { ...campaignData, startDate: date };
    setCampaignData(updatedData);
    
    setMessages(prev => [
      ...prev,
      { type: 'user', content: formatDate(date) },
      {
        type: 'summary',
        content: formatSummary(updatedData),
        isSummary: true,
        showActions: true
      }
    ]);
    
    setIsComplete(true);
  };

  const formatSummary = (data: CampaignData) => (
    <div className="space-y-3">
      <h3 className="font-semibold text-lg mb-4">Campaign Summary</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-gray-600 text-sm">Campaign Name</p>
          <p className="font-medium">{data.name}</p>
        </div>
        <div>
          <p className="text-gray-600 text-sm">Target Audience</p>
          <p className="font-medium">{data.targetAudience}</p>
        </div>
        <div>
          <p className="text-gray-600 text-sm">Campaign Goal</p>
          <p className="font-medium">{data.goal}</p>
        </div>
        <div>
          <p className="text-gray-600 text-sm">Start Date</p>
          <p className="font-medium">{formatDate(data.startDate)}</p>
        </div>
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200">
        <p className="text-sm text-gray-600">Would you like to create this campaign?</p>
        <div className="flex gap-4 mt-3">
          <button
            onClick={() => handleConfirmation(true)}
            disabled={isSubmitting}
            className="flex items-center gap-2 px-4 py-2 bg-[#09122C] text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? (
              <>
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                Creating...
              </>
            ) : (
              <>
                <CheckCircle size={18} />
                Confirm
              </>
            )}
          </button>
          <button
            onClick={() => handleConfirmation(false)}
            disabled={isSubmitting}
            className="flex items-center gap-2 px-4 py-2 border border-red-500 text-red-500 rounded-lg hover:bg-red-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <XCircle size={18} />
            Start Over
          </button>
        </div>
      </div>
    </div>
  );

  const handleConfirmation = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        setIsSubmitting(true);
        
        const newCampaign: CampaignData = {
          ...campaignData,
          status: 'active',  // Set status to active when creating
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        campaignService.createCampaign(newCampaign);

        // Show success message
        setMessages(prev => [...prev, {
          type: 'bot',
          content: "Great! Your campaign has been created successfully. Redirecting to campaigns page..."
        }]);
        
        // Redirect after success
        setTimeout(() => {
          navigate('/campaigns');
        }, 2000);

      } catch (error) {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: "Sorry, there was an error creating your campaign. Please try again."
        }]);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Reset the form
      setCurrentStep(0);
      setCampaignData({
        name: '',
        targetAudience: '',
        goal: '',
        startDate: '',
        status: 'draft'
      });
      setMessages([{
        type: 'bot',
        content: questions[0]
      }]);
      setIsComplete(false);
    }
  };

  const handleSendMessage = () => {
    if (!userInput.trim()) return;

    // Add user message
    const userMessage: Message = { type: 'user', content: userInput };
    setMessages(prev => [...prev, userMessage]);

    // Update campaign data based on current step
    const updatedData = { ...campaignData };
    switch (currentStep) {
      case 0:
        updatedData.name = userInput;
        break;
      case 1:
        updatedData.targetAudience = userInput;
        break;
      case 2:
        updatedData.goal = userInput;
        break;
    }
    setCampaignData(updatedData);

    // Clear input
    setUserInput('');

    // Show next question or date picker
    setTimeout(() => {
      if (currentStep === 2) { // Before the date question
        setMessages(prev => [
          ...prev,
          { type: 'bot', content: questions[currentStep + 1] },
          { type: 'date-picker', content: <DatePickerMessage onSelect={handleDateSelection} /> }
        ]);
      } else if (currentStep < questions.length - 1) {
        setMessages(prev => [...prev, { type: 'bot', content: questions[currentStep + 1] }]);
      }
      setCurrentStep(currentStep + 1);
    }, 500);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-73px)]">
      {/* Header */}
      <div className="bg-white border-b border-gray-200 px-6 py-4 flex items-center">
        <button 
          onClick={() => navigate('/campaigns')}
          className="mr-4 hover:text-gray-600"
        >
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-xl font-semibold">Create New Campaign</h1>
      </div>

      {/* Chat Area */}
      <div className="flex-1 bg-gray-50 p-6 overflow-y-auto">
        <div className="max-w-3xl mx-auto space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-md p-4 rounded-lg ${
                  message.type === 'user'
                    ? 'bg-[#09122C] text-white'
                    : message.type === 'summary'
                    ? 'bg-white border border-gray-200 w-full max-w-2xl'
                    : 'bg-white border border-gray-200'
                }`}
              >
                {message.content}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Input Area */}
      {!isComplete && currentStep < 3 && (
        <div className="bg-white border-t border-gray-200 px-6 py-4">
          <div className="max-w-3xl mx-auto flex gap-4">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Type your message..."
              className="flex-1 border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:border-[#09122C]"
            />
            <button
              onClick={handleSendMessage}
              disabled={!userInput.trim()}
              className="bg-[#09122C] text-white px-4 py-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;