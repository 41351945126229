import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './payrate-landing';
import ContactPage from './contact';
import PaymentAnalysisForm from './revenueCalculator'
import { Auth0Provider } from '@auth0/auth0-react';
import ProtectedRoute from './ProtectedRoute';
import CommConfig from './commConfig';
import LeftMenu from './leftMenu';
import CampaignPage from './campaign';
import { MenuProvider } from './menuContext';
import Layout from './layout';
import ChatWindow from './chatWindow';
import CDPL from './coder';

const App: React.FC = () => {
  return (
    <Auth0Provider
    domain="dev-gh7mg5dizrmjvgkk.us.auth0.com"
    clientId="vhVBZlKcjFaU7ZrF7T1Cr4s6LJhnhbeq"
    authorizationParams={{
      redirect_uri: window.location.origin + "/calculator"
    }}
  >
    <div>

    <Routes>
      <Route path="/" element={<LandingPage/>} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/coder" element={<CDPL />} />
      <Route path="/config" element={<CommConfig />} />
      <Route path="/campaigns" element={<CampaignPage />} />
      <Route path="/campaigns/new" element={<ChatWindow />} />
      <Route 
          path="/calculator" 
          element={
            <ProtectedRoute>
              <PaymentAnalysisForm />
            </ProtectedRoute>
          } 
        />
    </Routes>
    </div>
    </Auth0Provider>  
  );
};

export default App;