import React, { useState } from 'react';
import { MoreVertical, Plus } from 'lucide-react';
import { useMenu } from './menuContext';
import { useNavigate } from 'react-router-dom';

const CampaignPage = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const { isMenuOpen } = useMenu();
  const navigate = useNavigate();
  const handleNavigation = (path: string) => {
    navigate(path);
  }

  const campaigns = [
    { id: '1', name: 'Campaign A' },
    { id: '2', name: 'Campaign B' },
    { id: '3', name: 'Campaign C' },
    { id: '4', name: 'Campaign D' },
  ];

  const menuItems = [
    { id: 'edit', label: 'Edit Details', highlighted: true },
    { id: 'preferences', label: 'Edit Preferences' },
    { id: 'delete', label: 'Delete Campaign' },
    { id: 'duplicate', label: 'Duplicate' },
  ];

  const toggleDropdown = (campaignId: string) => {
    setActiveDropdown(activeDropdown === campaignId ? null : campaignId);
  };

  const handleClickOutside = () => {
    setActiveDropdown(null);
  };

  return (
    <div className={`p-8 transition-all duration-300 ${isMenuOpen ? 'ml-64' : 'ml-20'}`}>
      {/* Header with Add Campaign button */}
      <div className="flex justify-end mb-6">
        <button onClick={() => handleNavigation('/campaigns/new')} className="flex items-center gap-2 bg-[#09122C] text-white px-4 py-2 rounded-lg">
          <Plus size={20} />
          Add Campaign
        </button>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {campaigns.map((campaign) => (
          <div key={campaign.id} className="relative">
            {/* Campaign Card */}
            <div className="border border-gray-200 rounded-lg p-6 flex justify-between items-center">
              <h3 className="text-lg font-medium">{campaign.name}</h3>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown(campaign.id);
                }}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <MoreVertical size={20} className="text-gray-600" />
              </button>
            </div>

            {/* Dropdown Menu */}
            {activeDropdown === campaign.id && (
              <>
                <div
                  className="fixed inset-0 z-10"
                  onClick={handleClickOutside}
                />
                <div className="absolute right-0 top-12 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-20 py-1">
                  {menuItems.map((item) => (
                    <button
                      key={item.id}
                      className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${
                        item.highlighted ? 'bg-[#09122C] text-white hover:bg-[#09122C]' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveDropdown(null);
                      }}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CampaignPage;