import React, { useState } from 'react';
import { BarChart3, DollarSign, FileSpreadsheet, ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <button
        className="w-full px-6 py-4 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold text-gray-900">{question}</span>
        <ChevronDown 
          className={`w-5 h-5 text-gray-500 transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="px-6 pb-4 text-gray-600">
          {answer}
        </div>
      )}
    </div>
  );
};

const PayrateLanding = () => {
  const navigate = useNavigate();
  const handleContactNavigation = () => {
    navigate('/contact');
  }
  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
  
    return <button className="bg-emerald-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-emerald-700 transition-colors" onClick={() => loginWithRedirect()}>Try Our Revenue Calculator</button>;
  };
  
  const faqItems = [
    {
      question: "What is PayRate?",
      answer: "PayRate is your unified analytics platform for payment processing fees. We help platforms and marketplaces optimize their payment costs and streamline reconciliation through real-time data insights."
    },
    {
      question: "Which payment providers do you support?",
      answer: "We currently only support Stripe, but we're working on adding support for Adyen, Airwallex, and more. Let us know if you have a specific provider you'd like us to support!"
    },
    {
      question: "How quickly can I get started?",
      answer: "Setup takes less than 5 minutes. Simply login with your payment processor, and our no-code solution will automatically start analyzing your transaction data."
    },
    {
      question: "Is my data safe with Payrate?",
      answer: "Absolutely, we take data security very seriously. PayRate just uses transaction to build powerful analytics for you. We don't share your data with anyone. We are working on getting SOC 2 compliance."
    },
    {
      question: "What does it cost?",
      answer: "We offer transparent pricing starting from $0 to $100/month for growing businesses depending on number of transactions. Enterprise plans with custom features are available for larger organizations."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation Banner */}
      <nav className="bg-white py-4 px-6 border-b border-gray-200">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <div className="flex items-center gap-2 -ml-5">
            <div className="bg-emerald-500 rounded-full w-10 h-10 flex items-center justify-center">
              <span className="text-white font-bold text-xl">P</span>
            </div>
            <span className="text-gray-900 text-xl font-semibold">PayRate</span>
          </div>
          <button className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition-colors"  onClick={handleContactNavigation}>
            Join Waitlist
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="flex-1 space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
              Transform Your Payment Analytics
            </h1>
            <p className="text-lg text-gray-600">
              Real-time payment processing intelligence to optimize fees, automate reconciliation, and boost your bottom line.
            </p>
            <LoginButton></LoginButton>
          </div>
          <div className="flex-1">
            <div className="rounded-xl overflow-hidden shadow-2xl">
              <img 
                src="/images/insights-chart.png"
                alt="PayRate Analytics Dashboard"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-white py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Optimize Your Payment Operations
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center space-y-4">
              <div className="flex justify-center">
                <BarChart3 className="w-12 h-12 text-emerald-600" />
              </div>
              <h3 className="text-xl font-semibold">Fee Intelligence</h3>
              <p className="text-gray-600">
                Deep dive into payment processing fees across vendors. Track costs per transaction and receive instant alerts for fee anomalies.
              </p>
            </div>

            <div className="text-center space-y-4">
              <div className="flex justify-center">
                <DollarSign className="w-12 h-12 text-emerald-600" />
              </div>
              <h3 className="text-xl font-semibold">Revenue Optimization</h3>
              <p className="text-gray-600">
                Compare pricing strategies, run A/B tests, and leverage market benchmarks to maximize your payment revenue.
              </p>
            </div>

            <div className="text-center space-y-4">
              <div className="flex justify-center">
                <FileSpreadsheet className="w-12 h-12 text-emerald-600" />
              </div>
              <h3 className="text-xl font-semibold">Smart Reconciliation</h3>
              <p className="text-gray-600">
                Automate payment reconciliation with intelligent matching. Say goodbye to manual spreadsheets and hello to real-time insights.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Frequently Asked Questions
          </h2>
          
          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-emerald-600 py-16">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Optimize Your Payment Stack?
          </h2>
          <p className="text-xl text-emerald-100 mb-8">
            Join forward-thinking platforms and marketplaces using Payrate to drive efficiency and growth.
          </p>
          <button className="bg-white text-emerald-600 px-8 py-4 rounded-lg font-medium hover:bg-gray-100 transition-colors"  onClick={handleContactNavigation}>
            Get Started Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayrateLanding;