import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';

interface CardVolumes {
  commercialCardVolume: string;
  consumerCardVolume: string;
}

interface FormData {
  annualProcessingVolume: string;
  avgPaymentSize: string;
  buyRate: string;
  mastercard: CardVolumes;
  visa: CardVolumes;
  sellRate: string;
  expectedMargin: string;
}

interface Field {
  name: keyof FormData;
  label: string;
  placeholder: string;
  type?: 'card-volume' | 'regular';
}

interface CalculatedFees {
  actualFees: number;
  expectedFees: number;
  networkCostsConsumer: number;
  networkCostsCommercial: number;
  totalRevenue: number;
  netRevenue: number;
  totalSchemeFees: number;
  payout: number;
}

const PaymentAnalysisForm = () => {
  const { logout } = useAuth0();
  const [formData, setFormData] = useState<FormData>({
    annualProcessingVolume: '',
    avgPaymentSize: '',
    buyRate: '',
    mastercard: {
      commercialCardVolume: '',
      consumerCardVolume: ''
    },
    visa: {
      commercialCardVolume: '',
      consumerCardVolume: ''
    },
    sellRate: '',
    expectedMargin: ''
  });

  const [calculatedFees, setCalculatedFees] = useState<CalculatedFees>({
    actualFees: 0,
    expectedFees: 0,
    networkCostsConsumer: 0,
    networkCostsCommercial: 0,
    totalRevenue: 0,
    netRevenue: 0,
    totalSchemeFees: 0,
    payout: 0,
  });

  const [showNetworkCosts, setShowNetworkCosts] = useState(false);

  const getTotalVolume = () => {
    const mcCommercial = parseFloat(formData.mastercard.commercialCardVolume) || 0;
    const mcConsumer = parseFloat(formData.mastercard.consumerCardVolume) || 0;
    const visaCommercial = parseFloat(formData.visa.commercialCardVolume) || 0;
    const visaConsumer = parseFloat(formData.visa.consumerCardVolume) || 0;
    
    return mcCommercial + mcConsumer + visaCommercial + visaConsumer;
  };

  useEffect(() => {
    const calculateFees = () => {
      const processingVolume = parseFloat(formData.annualProcessingVolume) || 0;
      const avgPaymentSize = parseFloat(formData.avgPaymentSize) || 0;
      const numberOfTxn = avgPaymentSize ? processingVolume / avgPaymentSize : 0;
      
      // Calculate Mastercard fees
      const mastercardConsumerPercentage = parseFloat(formData.mastercard.consumerCardVolume) || 0;
      const mastercardConsumerVolume = (mastercardConsumerPercentage / 100) * processingVolume;
      const mastercardNetworkFeesConsumer = (mastercardConsumerVolume * (0.25/100));
      
      const mastercardCommercialPercentage = parseFloat(formData.mastercard.commercialCardVolume) || 0;
      const mastercardCommercialVolume = (mastercardCommercialPercentage / 100) * processingVolume;
      const mastercardNetworkFeesCommercial = (mastercardCommercialVolume * (1.90/100));
      const mastercardSchemeFees = numberOfTxn * 0.038;
      
      // Calculate Visa fees
      const visaConsumerPercentage = parseFloat(formData.visa.consumerCardVolume) || 0;
      const visaConsumerVolume = (visaConsumerPercentage / 100) * processingVolume;
      const visaNetworkFeesConsumer = (visaConsumerVolume * 0.002);
      
      const visaCommercialPercentage = parseFloat(formData.visa.commercialCardVolume) || 0;
      const visaCommercialVolume = (visaCommercialPercentage / 100) * processingVolume;
      const visaNetworkFeesCommercial = (visaCommercialVolume * (1.90/100));
      const visaSchemeFees = numberOfTxn * 0.0082;
      
      // Calculate totals
      const buyRate = parseFloat(formData.buyRate) || 0;
      const expectedFees = processingVolume * (buyRate / 100);
      const totalNetworkFeesConsumer = mastercardNetworkFeesConsumer + visaNetworkFeesConsumer;
      const totalNetworkFeesCommercial = mastercardNetworkFeesCommercial + visaNetworkFeesCommercial;
      const totalSchemeFees = mastercardSchemeFees + visaSchemeFees;
      const actualFees = expectedFees + totalNetworkFeesConsumer + totalNetworkFeesCommercial + totalSchemeFees;

      // Calculate revenue
      const sellRate = parseFloat(formData.sellRate) || 0;
      const totalRevenue = processingVolume * (sellRate / 100);
      const netRevenue = totalRevenue - actualFees;
      const payout = processingVolume - totalRevenue;
      
      setCalculatedFees({
        actualFees,
        expectedFees,
        networkCostsConsumer: totalNetworkFeesConsumer,
        networkCostsCommercial: totalNetworkFeesCommercial,
        totalRevenue,
        netRevenue,
        totalSchemeFees,
        payout
      });
    };

    calculateFees();
  }, [formData]);

  const handleCardVolumeChange = (
    network: 'mastercard' | 'visa',
    type: 'commercialCardVolume' | 'consumerCardVolume',
    value: string
  ) => {
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      const numValue = parseFloat(value) || 0;
      const currentTotal = getTotalVolume();
      const existingValue = parseFloat(formData[network][type]) || 0;
      const newTotal = currentTotal - existingValue + numValue;

      if (newTotal <= 100) {
        setFormData(prev => ({
          ...prev,
          [network]: {
            ...prev[network],
            [type]: value
          }
        }));
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const regularFields: Field[] = [
    { 
      name: 'annualProcessingVolume', 
      label: 'Annual Processing Volume ($)',
      placeholder: 'Enter volume',
      type: 'regular'
    },
    { 
      name: 'avgPaymentSize', 
      label: 'Average Payment Size ($)',
      placeholder: 'Enter amount as number',
      type: 'regular'
    },
    { 
      name: 'buyRate', 
      label: 'Buy Rate %',
      placeholder: 'Enter rate you will pay to the PSP',
      type: 'regular'
    },
    { 
      name: 'sellRate', 
      label: 'Sell Rate %',
      placeholder: 'Enter rate you will charge per transaction',
      type: 'regular'
    },
  ];

  const totalVolumePercentage = getTotalVolume();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation Banner */}
      <nav className="bg-white py-3 px-4 border-b border-gray-200 sticky top-0 z-10">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="bg-emerald-500 rounded-full w-8 h-8 flex items-center justify-center">
              <span className="text-white font-bold text-lg">P</span>
            </div>
            <span className="text-gray-900 text-lg font-semibold">PayRate</span>
          </div>
         <LogoutButton></LogoutButton>
        </div>
      </nav>

      <div className="p-4 max-w-[90rem] mx-auto">
      <div className="flex gap-6">
      <div className="w-2/4 rounded-lg shadow-lg p-6" style={{ backgroundColor: 'rgb(0, 118, 88)' }}>
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-white">Payment Fees Calculator</h2>
        </div>
        <div className="space-y-6">
          {regularFields.map(field => (
            <div key={field.name} className="space-y-2">
              <label 
                htmlFor={field.name}
                className="block text-sm font-medium text-white"
              >
                {field.label}
              </label>
              <input
                id={field.name}
                name={field.name}
                type="text"
                value={formData[field.name as keyof FormData] as string}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full px-3 py-2 bg-white bg-opacity-90 border border-emerald-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:border-emerald-300 text-emerald-900 placeholder-emerald-700"
                placeholder={field.placeholder}
              />
            </div>
          ))}

          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-white">Card Volumes</h3>
              <div className={`text-sm ${totalVolumePercentage === 100 ? 'text-emerald-200' : 'text-yellow-200'}`}>
                Total Volume: {totalVolumePercentage}% {totalVolumePercentage !== 100 && '(Should equal 100%)'}
              </div>
            </div>
            
            {/* Mastercard Group */}
            <div className="border border-emerald-300 bg-emerald-900 bg-opacity-20 rounded-lg p-4 space-y-4">
              <h4 className="font-medium text-white">Mastercard</h4>
              <div className="space-y-4">
                {/* Card volume inputs for Mastercard */}
                <div>
                  <label className="block text-sm font-medium text-white">
                    Commercial Card Volume %
                  </label>
                  <input
                    type="text"
                    value={formData.mastercard.commercialCardVolume}
                    onChange={(e) => handleCardVolumeChange('mastercard', 'commercialCardVolume', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white bg-opacity-90 border border-emerald-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:border-emerald-300 text-emerald-900 placeholder-emerald-700"
                    placeholder="Enter commercial card volume"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-white">
                    Consumer Card Volume %
                  </label>
                  <input
                    type="text"
                    value={formData.mastercard.consumerCardVolume}
                    onChange={(e) => handleCardVolumeChange('mastercard', 'consumerCardVolume', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white bg-opacity-90 border border-emerald-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:border-emerald-300 text-emerald-900 placeholder-emerald-700"
                    placeholder="Enter consumer card volume"
                  />
                </div>
              </div>
            </div>

            {/* Visa Group */}
            <div className="border border-emerald-300 bg-emerald-900 bg-opacity-20 rounded-lg p-4 space-y-4">
              <h4 className="font-medium text-white">Visa</h4>
              <div className="space-y-4">
                {/* Card volume inputs for Visa */}
                <div>
                  <label className="block text-sm font-medium text-white">
                    Commercial Card Volume %
                  </label>
                  <input
                    type="text"
                    value={formData.visa.commercialCardVolume}
                    onChange={(e) => handleCardVolumeChange('visa', 'commercialCardVolume', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white bg-opacity-90 border border-emerald-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:border-emerald-300 text-emerald-900 placeholder-emerald-700"
                    placeholder="Enter commercial card volume"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-white">
                    Consumer Card Volume %
                  </label>
                  <input
                    type="text"
                    value={formData.visa.consumerCardVolume}
                    onChange={(e) => handleCardVolumeChange('visa', 'consumerCardVolume', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white bg-opacity-90 border border-emerald-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-300 focus:border-emerald-300 text-emerald-900 placeholder-emerald-700"
                    placeholder="Enter consumer card volume"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Results section */}
      <div className="w-1/3 rounded-lg shadow-lg p-6 h-fit" style={{backgroundColor: 'rgb(184 225 210)'}}>
        <div className="space-y-6">
          {/* Revenue Section */}
          <div className="pb-4 border-b">
            <h3 className="text-lg font-semibold mb-4">Revenue</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Total Revenue
                </label>
                <div className="text-2xl font-bold text-green-600 mt-1">
                  ${calculatedFees.totalRevenue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
                <p>
                  <label className="block text-sm font-medium text-gray-700">
                  (payout : ${calculatedFees.payout.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}) </label> </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Net Revenue
                </label>
                <div className="text-2xl font-bold text-green-600 mt-1">
                  ${calculatedFees.netRevenue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Fees Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Fee Breakdown</h3>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Actual Payment Processing Fees
                </label>
          {/* Continue from the Fees Section */}
          <div className="text-2xl font-bold text-purple-600 mt-1">
            ${calculatedFees.actualFees.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
  })}
</div>

  <button
type="button"
onClick={() => setShowNetworkCosts(!showNetworkCosts)}
className="flex items-center mt-2 text-sm text-gray-600 hover:text-gray-900"
>
{showNetworkCosts ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
<span className="ml-1">Network Costs Breakdown (Interchange + Scheme Fees)</span>
  </button>

  {showNetworkCosts && (
<div className="mt-2 pl-4 space-y-2 border-l-2 border-gray-200">
  <div>
    <label className="block text-sm font-medium text-gray-600">
      Consumer Cards
    </label>
    <div className="text-lg font-semibold text-purple-600">
      ${calculatedFees.networkCostsConsumer.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </div>
  </div>
  <div>
    <label className="block text-sm font-medium text-gray-600">
      Commercial Cards
    </label>
    <div className="text-lg font-semibold text-purple-600">
      ${calculatedFees.networkCostsCommercial.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </div>
  </div>
  <div>
    <label className="block text-sm font-medium text-gray-600">
      Total Scheme Fees
    </label>
    <div className="text-lg font-semibold text-purple-600">
      ${calculatedFees.totalSchemeFees.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </div>
  </div>
</div>
  )}
  </div>

  <div>
<label className="block text-sm font-medium text-gray-700">
Expected Payment Processing Fees
</label>
<div className="text-2xl font-bold text-purple-600 mt-1">
${calculatedFees.expectedFees.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
</div>
  </div>

  <div className="pt-4 border-t">
<label className="block text-sm font-medium text-gray-700">
Difference (Fees)
</label>
<div className={`text-2xl font-bold mt-1 ${
calculatedFees.actualFees - calculatedFees.expectedFees > 0 
  ? 'text-red-600' 
  : 'text-green-600'
}`}>
${Math.abs(calculatedFees.actualFees - calculatedFees.expectedFees).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
</div>
  </div>
</div>
</div>  
</div>
</div>
</div>
      </div>
</div>
);
};

export default PaymentAnalysisForm;