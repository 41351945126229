// src/api/services/campaignService.ts
import { CampaignData, CampaignListResponse, CampaignResponse } from '@/types/campaign';
import axiosInstance from '../axiosConfig';
import { ENDPOINTS } from '../endpoints';

export const campaignService = {
    createCampaign: async (campaignData: CampaignData): Promise<CampaignResponse> => {
      const response = await axiosInstance.post<CampaignResponse>(ENDPOINTS.CAMPAIGNS.CREATE, campaignData);
      return response.data;
    },
    
    listCampaigns: async (): Promise<CampaignListResponse> => {
      const response = await axiosInstance.get<CampaignListResponse>(ENDPOINTS.CAMPAIGNS.LIST);
      return response.data;
    },
    
  };