import React, { useState } from 'react';
import { ChevronDown, Plus,  Mail, Home, User } from 'lucide-react';
import LeftMenu from './leftMenu';

// Define types for section names
type SectionName = 'language' | 'personalization' | 'tone' | 'subjectLength' | 'emailLength' | 'goal' | 'framework';

type OpenSectionsState = {
  [K in SectionName]: boolean;
};

const CommConfig = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [selectedPosition, setSelectedPosition] = useState('In the P.S Section');
  const [selectedTone, setSelectedTone] = useState('Conversational');
  const [selectedLength, setSelectedLength] = useState('≤ 3 words');
  const [selectedEmailLength, setSelectedEmailLength] = useState('< 75 words');
  const [selectedGoal, setSelectedGoal] = useState('Create interest');
  const [selectedFramework, setSelectedFramework] = useState('PAS');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  
  const [openSections, setOpenSections] = useState<OpenSectionsState>({
    language: true,
    personalization: true,
    tone: true,
    subjectLength: true,
    emailLength: true,
    goal: true,
    framework: true
  });

  const toggleSection = (section: SectionName) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="p-6 pl-[88px]">
     {/* Main Content */}
     <div className={`flex-1 p-6 transition-all duration-300 ${isDrawerOpen ? 'mr-96' : 'mr-0'}`}>
        {/* Header */}
        <div className="flex justify-end mb-6">
          <button className="flex items-center gap-2 bg-[#09122C] text-white px-4 py-2 rounded-lg">
            <Plus size={20} />
            Add Product
          </button>
        </div>

      {/* Tool Selector */}
      <div className="mb-8 flex items-center gap-6">
        <select className="w-full max-w-xl border border-gray-200 rounded-lg p-3 text-gray-700">
          <option>Payment Intelligence tool</option>
        </select>
      </div>

      {/* Main Content */}
      <div className="border border-gray-200 rounded-2xl p-6">
        <h2 className="text-xl font-semibold mb-6">Final Email Output</h2>
        
        <div className="bg-[#EEEDEB] p-6 rounded-lg">
          <div className="mb-6">
            <label className="block mb-2">Copy-Paste the LinkedIn URL of the person you're reaching out to:</label>
            <input 
              type="text" 
              placeholder="Paste here..."
              className="w-full p-3 border border-gray-200 rounded-lg"
            />
          </div>
          
          <div className="mb-6">
            <label className="block mb-2">Choose personalization method:</label>
            <select className="w-full p-3 border border-gray-200 rounded-lg">
              <option>Auto Generate</option>
              <option>Manual</option>
            </select>
          </div>
          
          <div className="flex justify-end">
            <button className="bg-[#09122C] text-white px-6 py-2 rounded-lg">
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>  
    </div>

    {/* Side Drawer */}
    <div className={`fixed top-[73px] right-0 h-[calc(100vh-73px)] w-96 bg-white border-l border-gray-200 transform transition-transform duration-300 ease-in-out overflow-y-auto ${
        isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <div className="p-6 space-y-6">
          {/* Output Language */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('language')}>
              <h3 className="text-lg font-semibold">Output language</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.language ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex flex-wrap gap-3 transition-all duration-200 ${openSections.language ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['English', 'French', 'German', 'Spanish', 'Portuguese'].map((lang) => (
                <button
                  key={lang}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedLanguage === lang
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {lang}
                </button>
              ))}
            </div>
          </div>

          {/* Personalization */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('personalization')}>
              <h3 className="text-lg font-semibold">Personalization</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.personalization ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex gap-3 transition-all duration-200 ${openSections.personalization ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['In the P.S Section', 'Start of the email'].map((pos) => (
                <button
                  key={pos}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedPosition === pos
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedPosition(pos)}
                >
                  {pos}
                </button>
              ))}
            </div>
          </div>

          {/* Tone */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('tone')}>
              <h3 className="text-lg font-semibold">Tone of the email</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.tone ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex flex-wrap gap-3 transition-all duration-200 ${openSections.tone ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['Conversational', 'Friendly', 'Formal', 'Persuasive', 'Professional', 'Urgent', 'Creative', 'Direct'].map((tone) => (
                <button
                  key={tone}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedTone === tone
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedTone(tone)}
                >
                  {tone}
                </button>
              ))}
            </div>
          </div>

          {/* Subject Line Length */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('subjectLength')}>
              <h3 className="text-lg font-semibold">Length of subject line</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.subjectLength ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex gap-3 transition-all duration-200 ${openSections.subjectLength ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['1 word', '≤ 3 words', '≤ 5 words'].map((length) => (
                <button
                  key={length}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedLength === length
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedLength(length)}
                >
                  {length}
                </button>
              ))}
            </div>
          </div>

          {/* Email Length */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('emailLength')}>
              <h3 className="text-lg font-semibold">Length of the email</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.emailLength ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex flex-wrap gap-3 transition-all duration-200 ${openSections.emailLength ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['< 50 words', '< 75 words', '< 100 words', '< 125 words'].map((length) => (
                <button
                  key={length}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedEmailLength === length
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedEmailLength(length)}
                >
                  {length}
                </button>
              ))}
            </div>
          </div>

          {/* Goal of the email */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('goal')}>
              <h3 className="text-lg font-semibold">Goal of the email</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.goal ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex flex-wrap gap-3 transition-all duration-200 ${openSections.goal ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['Book a call', 'Create interest', 'Ask a question', 'Negative CTA', 'Share a lead magnet'].map((goal) => (
                <button
                  key={goal}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedGoal === goal
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedGoal(goal)}
                >
                  {goal}
                </button>
              ))}
            </div>
          </div>

          {/* Email Framework */}
          <div className="pb-6">
            <div className="flex items-center justify-between mb-4 cursor-pointer" onClick={() => toggleSection('framework')}>
              <h3 className="text-lg font-semibold">Email Framework (Writing Style)</h3>
              <ChevronDown size={20} className={`transform transition-transform duration-200 ${openSections.framework ? '' : '-rotate-180'}`} />
            </div>
            <div className={`flex flex-wrap gap-3 transition-all duration-200 ${openSections.framework ? 'opacity-100 h-auto' : 'opacity-0 h-0 overflow-hidden'}`}>
              {['PAS', 'AIDA', 'Why Us Why Now', 'Before-After-Bridge'].map((framework) => (
                <button
                  key={framework}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedFramework === framework
                      ? 'bg-[#09122C] text-white border-transparent'
                      : 'border-gray-200 text-gray-700'
                  }`}
                  onClick={() => setSelectedFramework(framework)}
                >
                  {framework}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommConfig;