import React, { useState } from 'react';
import { Search, LineChart, AlertTriangle, Brain } from 'lucide-react';

interface FAQProps {
  question: string;
  answer: string;
}

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface StepProps {
  number: string;
  title: string;
  description: string;
}

const FAQ = ({ question, answer }: FAQProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-800 rounded-lg">
      <button
        className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-800/50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold">{question}</span>
        <span className="ml-4 transition-transform duration-200" style={{ transform: isOpen ? 'rotate(180deg)' : '' }}>
          ▼
        </span>
      </button>
      {isOpen && (
        <div className="px-6 py-4 border-t border-gray-800">
          <p className="text-gray-400">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <div className="p-6 rounded-lg" style={{ 
    background: 'linear-gradient(135deg, rgba(17, 121, 252, 0.1), rgba(220, 130, 252, 0.7))',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: '0.5rem',
    padding: '1.5rem',
  }}>
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const CDPL = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <nav className="sticky top-0 z-50 flex items-center justify-between p-6 backdrop-blur-md bg-black/80 border-b border-gray-800">
        <div className="flex items-center">
          <h1
          className="text-4xl font-bold mb-6" 
          style={{ 
            background: 'linear-gradient(to right, #1179fc, #dc82fc)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            display: 'inline-block'
            }}>
            Spil
            </h1>
          <div className="ml-8 space-x-4" >
            <a href="#features" className="hover:opacity-80 text-white">Features</a>
            <a href="#how-it-works" className="hover:opacity-80 text-white">How it Works</a>
            <a href="#benefits" className="hover:opacity-80 text-white">Benefits</a>
          </div>
        </div>
        <div className="space-x-4">
          <button className="px-4 py-2 text-gray-300 hover:text-white">Documentation</button>
          <button className="px-4 py-2 rounded-md hover:opacity-80 text-white" style={{ backgroundColor: '#4f46e5' }}>Get Started</button>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-black">
        <div className="max-w-6xl mx-auto px-6 py-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="text-5xl font-bold mb-6">
                Optimize Your Code With Every Update
              </h1>
              <h2 
                  className="text-4xl font-bold mb-6" 
                  style={{ 
                    background: 'linear-gradient(to right, #1179fc, #dc82fc)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    display: 'inline-block'
                  }}>
                Cut Costs.
              </h2>
              <p className="text-xl text-gray-300 mb-8">
                Automatically analyze and optimize code patterns in your Pull Requests. Improve
                performance and reduce technical debt.
              </p>
              <div className="flex space-x-4">
                <button className="px-6 py-3 rounded-md hover:opacity-80 text-white" style={{ backgroundColor: '#4f46e5' }}>
                  Get Started
                </button>
                <button className="px-6 py-3 border border-gray-700 rounded-md hover:border-gray-600">
                  View Demo
                </button>
              </div>
            </div>
            <div style={{ 
                background: 'linear-gradient(135deg, rgba(17, 121, 252, 0.1), rgba(220, 130, 252, 0.7))',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderRadius: '0.5rem',
                padding: '1.5rem',
              }} className="font-mono text-sm" >
              <div className="mb-6">
                <p className="text-gray-400 mb-2">// Before optimization</p>
                <div className="space-y-1">
                  <p><span className="text-blue-400">logger</span>.debug(<span className="text-green-400">"Starting process"</span>);</p>
                  <p><span className="text-blue-400">logger</span>.info(<span className="text-green-400">"Processing item"</span>, {'{ "item": "example" }'});</p>
                  <p><span className="text-blue-400">logger</span>.debug(<span className="text-green-400">"Process completed"</span>);</p>
                </div>
              </div>
              <div>
                <p className="text-gray-400 mb-2">// After optimization</p>
                <div className="space-y-1">
                  <p><span className="text-blue-400">logger</span>.info(<span className="text-green-400">"Processing item"</span>, {'{'}</p>
                  <p className="ml-4">item: "example",</p>
                  <p className="ml-4">duration: process.duration</p>
                  <p>{'}'});</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-black">
        <div className="max-w-6xl mx-auto px-6 py-16" id="features" >
          <h2 className="text-3xl font-bold text-center mb-4">Powerful Features</h2>
          <p className="text-center text-gray-400 mb-12">
            Everything you need to optimize your development workflow
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"  >
            <FeatureCard
              icon={<Search className="w-8 h-8" style={{ color: '#4f46e5' }} />}
              title="Intelligent Analysis"
              description="Automatically detect inefficient patterns in your codebase."
            />
            <FeatureCard
              icon={<LineChart className="w-8 h-8" style={{ color: '#4f46e5' }} />}
              title="Performance Tracking"
              description="Track and optimize your code metrics with detailed analytics."
            />
            <FeatureCard
              icon={<AlertTriangle className="w-8 h-8" style={{ color: '#4f46e5' }} />}
              title="Early Detection"
              description="Catch issues before they impact production performance."
            />
            <FeatureCard
              icon={<Brain className="w-8 h-8" style={{ color: '#4f46e5' }} />}
              title="Smart Suggestions"
              description="Get intelligent recommendations based on best practices."
            />
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="bg-black" id="how-it-works">
        <div className="relative py-24">
          <div className="max-w-6xl mx-auto px-6">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4">How It Works</h2>
              <p className="text-xl text-gray-400">
                Simple integration, powerful results
              </p>
            </div>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              {/* Code Editor Image */}
              <div className="relative">
                <img src="/images/howitworks.jpeg" alt="Code Editor" className="rounded-lg"/>
              </div>

              {/* Steps */}
              <div className="space-y-6">
                <div className="rounded-lg p-6 border border-gray-700" style={{ 
                background: 'linear-gradient(135deg, rgba(17, 121, 252, 0.1), rgba(220, 130, 252, 0.7))',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderRadius: '0.5rem',
                padding: '1.5rem',
              }}>
                  <div className="flex items-start gap-4">
                    <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center text-lg">
                      1
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Install the GitHub App</h3>
                      <p className="text-gray-400">Add LogOptimizer to your repositories with a single click</p>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg p-6 border border-gray-700" style={{ 
                background: 'linear-gradient(135deg, rgba(17, 121, 252, 0.1), rgba(220, 130, 252, 0.7))',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderRadius: '0.5rem',
                padding: '1.5rem',
              }}>
                  <div className="flex items-start gap-4">
                    <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center text-lg">
                      2
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Automatic PR Analysis</h3>
                      <p className="text-gray-400">We analyze your Pull Requests for logging patterns</p>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg p-6 border border-gray-700" style={{ 
                background: 'linear-gradient(135deg, rgba(17, 121, 252, 0.1), rgba(220, 130, 252, 0.7))',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderRadius: '0.5rem',
                padding: '1.5rem',
              }}>
                  <div className="flex items-start gap-4">
                    <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center text-lg">
                      3
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Get Optimization Insights</h3>
                      <p className="text-gray-400">Receive detailed reports and suggestions for improvement</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-black">
        <div className="max-w-6xl mx-auto px-6 py-16" id="faq">
          <h2 className="text-3xl font-bold text-center mb-4">Frequently Asked Questions</h2>
          <p className="text-center text-gray-400 mb-12">
            Everything you need to know about CodeAnalyzer
          </p>
          <div className="space-y-4 max-w-3xl mx-auto">
            <FAQ 
              question="How does CodeAnalyzer handle my data?"
              answer="CodeAnalyzer communicates with LLM providers to generate code reviews. We send code diffs along with contextual data about the code to improve code reviews and provide better suggestions. The data is encrypted in transit using transport layer security (TLS). Proprietary code is never used to train or improve the models in any way. Queries to the LLMs are ephemeral, and no data is stored or logged by the LLMs."
            />
            <FAQ 
              question="How do I get started with CodeAnalyzer?"
              answer="Getting started is simple. Just install our GitHub app, and we'll automatically begin analyzing your pull requests. No configuration needed - though you can customize settings to match your team's preferences."
            />
            <FAQ 
              question="What types of issues can CodeAnalyzer detect?"
              answer="CodeAnalyzer can identify a wide range of optimization opportunities, including redundant logging patterns, inefficient code structures, and potential performance bottlenecks. We provide specific, actionable suggestions for improvement."
            />
            <FAQ 
              question="Does CodeAnalyzer support private repositories?"
              answer="Yes, CodeAnalyzer fully supports private repositories. We maintain strict security protocols and data privacy measures to ensure your code remains secure and confidential at all times."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CDPL;